<template>
  <div class="container formConfig">
    <div class="model-view-item" v-if="!previewFlag">
      <h3>类型添加</h3>
      <div class="model-item-box">
        <template v-for="(item, index) in modelData" :key="index">
          <div class="model-item" @click="handleAddModel(item)">
            <img :src="item.icon" alt="图标" />
            <div>{{ item.name }}</div>
          </div>
        </template>
      </div>
    </div>

    <!-- 展示内容区域 -->
    <div class="model-view-content" v-if="!previewFlag">
      <div class="content">
        <!-- <div class="content-item">
          <el-input v-model="title" placeholder="请输入标题" class="inputBox" />
          <el-input
            v-model="description"
            placeholder="请输入内容描述"
            class="normalInput"
            style="margin-top: 20px"
          />
        </div> -->

        <draggable
          class="draggable"
          group="model"
          ghostClass="ghost"
          @change="handleChange"
          :list="contentData"
          item-key="index"
        >
          <template #item="{ element }">
            <div class="form-item-box">
              <!-- 
              :class="element.index == nowCheckIndex ? 'check' : ''" -->
              <single-input
                v-if="element.type == 'input'"
                :res="element"
                :index="element.index"
                :nowIndex="nowCheckIndex"
                @delete-form-item="deleteFormItem"
              ></single-input>

              <radio-input
                v-if="element.type == 'radio'"
                :res="element"
                :index="element.index"
                :nowIndex="nowCheckIndex"
                @delete-form-item="deleteFormItem"
              ></radio-input>

              <checkbox-input
                v-if="element.type == 'checkBox'"
                :res="element"
                :index="element.index"
                :nowIndex="nowCheckIndex"
                @delete-form-item="deleteFormItem"
              ></checkbox-input>

              <timePicker
                v-if="element.type == 'timePicker'"
                :res="element"
                :index="element.index"
                :nowIndex="nowCheckIndex"
                @delete-form-item="deleteFormItem"
              ></timePicker>

              <select-input
                v-if="element.type == 'select'"
                :res="element"
                :index="element.index"
                :nowIndex="nowCheckIndex"
                @delete-form-item="deleteFormItem"
              ></select-input>

              <customized
                v-if="
                  element.type == 'group_customized' ||
                  element.type == 'exh_customized' ||
                  element.type == 'customized'
                "
                :res="element"
                :index="element.index"
                :nowIndex="nowCheckIndex"
                @delete-form-item="deleteFormItem"
              ></customized>

              <single-textarea
                v-if="element.type == 'textarea'"
                :res="element"
                :index="element.index"
                :nowIndex="nowCheckIndex"
                @delete-form-item="deleteFormItem"
              ></single-textarea>

              <numberInput
                v-if="element.type == 'numberInput'"
                :res="element"
                :index="element.index"
                :nowIndex="nowCheckIndex"
                @delete-form-item="deleteFormItem"
              ></numberInput>
            </div>
          </template>
        </draggable>
      </div>

      <div class="controlBox">
        <!-- <el-button type="info" plain class="publish" @click="preview">
          预览
        </el-button> -->
        <el-button type="primary" class="publish" @click="publish">
          发布
        </el-button>
      </div>
    </div>

    <div v-if="previewFlag" style="flex: 1">
      <preview-model :form="contentData"></preview-model>
    </div>
    <!-- 展示内容配置区域 -->
    <div class="model-view-config"></div>
  </div>
</template>

<script>
  import {
    defineComponent,
    defineAsyncComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  import { modelData } from './config.js'
  import { getquestioniareDetail, editQuestioniareList } from '@/api/form'
  export default defineComponent({
    name: 'index.vue',
    components: {
      Draggable: defineAsyncComponent(() => import('vuedraggable')),
      singleInput: defineAsyncComponent(() =>
        import('./components/single-input.vue')
      ),
      radioInput: defineAsyncComponent(() =>
        import('./components/radio-input.vue')
      ),
      checkboxInput: defineAsyncComponent(() =>
        import('./components/checkbox-input.vue')
      ),
      previewModel: defineAsyncComponent(() =>
        import('./components/previewModel.vue')
      ),
      timePicker: defineAsyncComponent(() =>
        import('./components/timePicker.vue')
      ),
      selectInput: defineAsyncComponent(() =>
        import('./components/select-input.vue')
      ),
      customized: defineAsyncComponent(() =>
        import('./components/customized.vue')
      ),
      singleTextarea: defineAsyncComponent(() =>
        import('./components/textarea.vue')
      ),
      numberInput: defineAsyncComponent(() =>
        import('./components/number-input.vue')
      ),
    },
    setup() {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        modelData,
        title: '',
        description: '',
        contentData: [],
        nowCheckIndex: 0,
        previewFlag: false,
        basicData: [],
      })

      const handleAddModel = (item) => {
        console.log(state.contentData, item)
        state.contentData.push(Object.assign({}, item))
        state.contentData = proxy.sortData(
          JSON.parse(JSON.stringify(state.contentData))
        )
        //设置当前选中
        state.nowCheckIndex = state.contentData.length - 1
      }

      const deleteFormItem = (index) => {
        console.log(index)
        state.contentData.splice(index, 1)
        //设置当前选中
        state.nowCheckIndex = index - 1
        state.contentData = proxy.sortData(
          JSON.parse(JSON.stringify(state.contentData))
        )
      }

      // const publish = () => {
      //   console.log(state.contentData)
      // }

      const publish = async () => {
        if (!ruleFormContent(state.contentData)) {
          proxy.$baseMessage(
            `问题和描述不能为空`,
            'error',
            'vab-hey-message-error'
          )
          return false
        }

        const data = handleFormData(state.contentData)
        state.basicData.questionContent = JSON.stringify(data)
        let upData = await editQuestioniareList(state.basicData)
        console.log(upData)
        if (upData.code == 200) {
          proxy.$baseMessage(`保存成功`, 'success', 'vab-hey-message-success')
        }
      }

      // 非空校验
      const ruleFormContent = (content) => {
        let isComplete = true
        for (let val of content) {
          !!val.label ? (isComplete = true) : (isComplete = false)
        }
        console.log(isComplete)
        return isComplete
      }
      // form 表单数据拼接
      const handleFormData = (content) => {
        // for (let val of content) {
        //   console.log(val)
        //   // input 类型处理
        //   if (val.type == 'input') {
        //     val.rules[0].message = val.message
        //     val.rules[0].required = val.required
        //   }
        // }
        return content
      }

      const fetchData = async (code) => {
        const { data } = await getquestioniareDetail(code)
        console.log(data)
        state.basicData = data
        if (data.questionContent != 'null' && data.questionContent != null) {
          state.contentData = JSON.parse(data.questionContent)
        }
      }

      onMounted(() => {
        console.log(proxy.$route)
        fetchData(proxy.$route.query.code)
      })

      // 元素新增
      const handleChange = (evt) => {
        console.log(evt)
        // 元素排序
        if (evt.moved) {
          const { oldIndex, newIndex } = evt.moved
          proxy.nowCheckIndex = newIndex
          state.contentData = proxy.sortData(
            JSON.parse(JSON.stringify(state.contentData))
          )
        }
      }

      // 元素排序
      const sortData = (array) => {
        for (let i in array) {
          array[i].index = Number(i)
        }
        return array
      }

      return {
        ...toRefs(state),
        handleAddModel,
        deleteFormItem,
        publish,
        handleChange,
        sortData,
        fetchData,
      }
    },
  })
</script>
<style lang="scss" scoped>
  .container {
    display: flex;

    .model-view-item {
      width: 300px;
      height: 85vh;
      padding: 10px;
      background: #fff;
      .model-item-box {
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        margin-top: 20px;
        .model-item {
          text-align: center;
          cursor: pointer;
          width: 80px;
          margin-top: 20px;
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    .model-view-content {
      position: relative;
      width: 70vw;
      height: 85vh;
      padding-top: 20px;
      overflow-y: auto;
      background: #e9ebed;
      .controlBox {
        position: fixed;
        bottom: 20px;
        display: flex;
        justify-content: center;
        width: 70vw;
        .publish {
          display: block;
          width: 200px;
          height: 50px;
        }
      }
      .content {
        .content-item {
          width: 80%;
          min-height: 200px;
          padding: 30px 20px 0px 20px;
          margin: 0 auto;
          margin-bottom: 20px;
          background: #fff;
          border-radius: 20px;
          &.check {
            box-shadow: 10px 10px 5px #999;
          }
        }
        .form-item-box {
          width: 80%;
          min-height: 200px;
          margin: 0 auto;
          margin-bottom: 20px;
          background: #fff;
          border-radius: 20px;
          &.check {
            box-shadow: 10px 10px 5px #eb4b17;
          }
        }
      }
    }
  }
</style>

<style lang="scss">
  .inputBox {
    .el-input__inner {
      width: 100%;
      font-size: 26px;
      border: none;
    }
    .el-input__inner::placeholder {
      font-size: 26px;
      font-weight: bold;
      color: #333;
    }
  }
  .normalInput {
    .el-input__inner {
      width: 100%;
      font-size: 20px;
      border: none;
    }
  }

  .input_inp_name {
    .el-input__inner {
      width: 100%;
      padding-left: 0;
      font-size: 18px;
      font-weight: bold;
      border: none;
    }
  }
  .input_inp_desc {
    .el-input__inner {
      width: 100%;
      padding-left: 0;
      font-size: 14px;
      border: none;
    }
  }
  .input_inp_userInsert {
    .el-input__inner {
      width: 100%;
      height: 44px;
      font-size: 14px;
      line-height: 44px;
      border: 1px solid #c2c2c2;
    }
  }
</style>
